import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .get(`admin/ip-blocks?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.search ? "&search=" + pl.search : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });

        });
    },
    view({ rootGetters, dispatch }, id) {
        return new Promise(function(resolve) {
            $api
                .get(`admin/ip-block/${id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });
        });
    },
    create({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post("admin/ip-block", pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    update({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .put(`admin/ip-block/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }

                });
        });
    },
    delete({ rootGetters, dispatch }, pl) {
        return new Promise(async function(resolve) {
            $api
                .delete(`admin/ip-block/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
};

export default {
    namespaced: true,
    actions
};