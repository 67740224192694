import { createWebHistory, createRouter } from "vue-router";

import store from '@/state/store'
import routes from './routes'

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        // if (savedPosition) {
        //     console.log(savedPosition)
        //     return savedPosition
        // } else {
        return { x: 0, y: 0 }
        // }
    },
})


router.beforeEach(async (routeTo, routeFrom, next) => {
    const queryValidation = routeTo.meta.queryValidation;
    if (queryValidation) {
        const query = routeTo.query;
        for (const key in queryValidation) {
            const { validator, message } = queryValidation[key];
            if (!validator.test(query[key]) && query[key] != undefined) {
                alert(message);
                return;
            }
        }
    }

    const auth = store.getters["auth/authenticated"];
    if (auth) {
        store.commit('auth/triggerLoader', routeTo.path)
        await store.dispatch("auth/getProfile");
        store.commit('auth/triggerLoader')
    }


    if (routeTo.matched.some((record) => record.meta.requiresAuth)) {
        if (!auth) {
            next("/login");
        } else {
            next();
        }
    } else if (routeTo.matched.some((record) => record.meta.requiresVisitor)) {
        if (auth) {
            next("/");
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router