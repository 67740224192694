import { createStore } from 'vuex'

import modules from './modules'
const store = createStore({
  modules,
  state: {
    key: 0,
    notifications: [],
  },
  mutations: {
    increment(state, payload) {
      state.key += 1
    },
    addNotification(state, n) {
      state.notifications.unshift(n);
    },
    removeNotificationByIndex(state, index) {
      state.notifications.splice(index, 1)
    },
    removeNotificationById(state, id) {
      console.log(id)
      console.log(state.notifications)
      state.notifications.forEach((n, index) => {
        if (n.id === id) {
          state.notifications.splice(index, 1);
        }
      });
    },
    clearNotifications(state) {
      state.notifications = [];
    }
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

