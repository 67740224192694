import $api from "../api";
import axios from "axios";
import Swal from "sweetalert2";

export const state = {
    data: {
        list: [],
        links: [],
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
    },
};

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            // $api
            //     .post(`user/list${pl.page_no ? "?page=" + pl.page_no : ""}`, pl, {
            //         headers: {
            //             Authorization: rootGetters["auth/bearer_token"],
            //         },
            //     })
            //     .then(function(res) {
            //         if (res.status == 200) {
            //             resolve(res.data.data);
            //         }
            //     })
            //     .catch(function(err) {
            //         if (err.response.status == 401) {
            //             dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
            //         }
            //     });
            axios
                .get(`${process.env.VUE_APP_BACKEND_API}/data/activities.json`)
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
        });
    },
    read({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post(`user/read`, pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });
        });
    },
    create({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post("user/create", pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    console.log(res);
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        console.log(err);
                        resolve(err.response);
                    }
                });
        });
    },
    update({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post("user/update", pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Updating succeed",
                            text: res.data.message,
                        });
                        resolve(true);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        Swal.fire({
                            icon: "warning",
                            title: "Incorrect details",
                        })
                        resolve(err.response);
                    }
                });
        });
    },
    delete({ rootGetters, dispatch }, pl) {
        return new Promise(async function(resolve) {
            const confirmed = await Swal.fire({
                icon: "warning",
                title: "Are you sure?",
                text: "Delete the admin user",
                showCancelButton: true,
                confirmButtonColor: "#f46a6a",
            }).then((e) => {
                return e.isConfirmed;
            });
            if (confirmed) {
                $api
                    .post("user/delete", pl, {
                        headers: {
                            Authorization: rootGetters["auth/bearer_token"],
                        },
                    })
                    .then(function(res) {
                        console.log(res);
                        if (res.status == 200) {
                            resolve(res.data);
                        }
                    })
                    .catch(function(err) {
                        if (err.response.status == 401) {
                            dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                        }
                        if (err.response.status == 422) {
                            resolve(err.response);
                        }
                        resolve(false);
                    });
            }
        });
    },
    result({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post("user/result", pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        resolve(res);
                    }
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        console.log(err.response);
                        resolve(err.response);
                    }
                });
        });
    },
};

export const mutations = {
    setData(state, data) {
        state.data.list = data.data;
        state.data.links = data.links;
        state.data.current_page = data.current_page;
        state.data.last_page = data.last_page;
        state.data.per_page = data.per_page;
        state.data.total = data.total;
    },

    clearData(state) {
        state.data.list = [];
        state.data.links = [];
        state.data.current_page = 0;
        state.data.last_page = 0;
        state.data.per_page = 0;
        state.data.total = 0;
    },
};

export const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};