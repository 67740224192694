import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/player/bets?${pl.page_no ? "page=" + pl.page_no : ""}${pl.search ? "&search=" + pl.search : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${"&live=" + pl.live}${pl.status ? "&status=" + pl.status : ""}${pl.referrer ? "&referrer=" + pl.referrer : ""}${pl.referrer_username ? "&referrer_username=" + pl.referrer_username : ""}${pl.username ? "&username=" + pl.username : ""}${pl.agent_level ? "&agent_level=" + pl.agent_level : ""}${pl.provider ? "&provider=" + pl.provider : ""}${pl.vendor ? "&vendor=" + pl.vendor : ""}${pl.game ? "&game=" + pl.game : ""}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getLiveList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/player/bet/live?${pl.role ? "role=" + pl.role : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getListTotal({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/player/bet/list-total?${pl.provider ? "provider=" + pl.provider : ""}${pl.search ? "&search=" + pl.search : ""}${pl.vendor ? "&vendor=" + pl.vendor : ""}${"&live=" + pl.live}${pl.status ? "&status=" + pl.status : ""}${pl.referrer ? "&referrer=" + pl.referrer : ""}${pl.referrer_username ? "&referrer_username=" + pl.referrer_username : ""}${pl.username ? "&username=" + pl.username : ""}${pl.agent_level ? "&agent_level=" + pl.agent_level : ""}${pl.game ? "&game=" + pl.game : ""}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    view({ rootGetters, dispatch }, id) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/player/bet/${id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    distributeCommission({ rootGetters, dispatch }, id) {
        return new Promise(function (resolve) {
            $api
                .post(`admin/player/bet/distribute-commission/${id}`, {}, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    resolve({
                        success: false,
                        data: err.response.data
                    });
                });
        });
    },
    setResult({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post(`admin/player/bet/set-result/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    resolve({
                        success: false,
                        data: err.response.data
                    });
                });
        });
    },
    update({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .put(`admin/${pl.user || 'user'}/bet/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }

                });
        });
    },
    delete({ rootGetters, dispatch }, pl) {
        return new Promise(async function (resolve) {
            $api
                .delete(`admin/player/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
};

export default {
    namespaced: true,
    actions
};