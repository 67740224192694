import { sassNull } from "sass";
import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/${pl.log_type}-logs?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.column ? "&column=" + pl.column : ""}${pl.username ? "&username=" + pl.username : ""}${pl.search ? "&search=" + pl.search : ""}${pl.function ? "&function=" + pl.function : ""}${pl.role ? "&role=" + pl.role : ""}${pl.status ? "&status=" + pl.status : ""}${pl.category ? "&category=" + pl.category : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getAgentCommissionList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/asset-log/agent-commissions?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.search ? "&search=" + pl.search : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    getAgentCommissionListTotal({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/asset-log/agent-commission/list-total?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.search ? "&search=" + pl.search : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    else {
                        resolve();
                    }
                });
        });
    },
    getAssetLogDetailed({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/asset-log/detailed?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.search ? "&search=" + pl.search : ""}${pl.type ? "&type=" + pl.type : ""}${"&with_bets=" + pl.with_bets}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    else {
                        resolve();
                    }
                });
        });
    },
};

export default {
    namespaced: true,
    actions
};