import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .get(`admin/tickets?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.search ? "&search=" + pl.search : ""}${pl.read!=null ? "&read=" + pl.read : ""}${pl.level ? "&level=" + pl.level : ""}${pl.type ? "&type=" + pl.type : ""}${pl.role ? "&role=" + pl.role : ""}${pl.username ? "&username=" + pl.username : ""}${pl.status ? "&status=" + pl.status : ""}${pl.read!=null ? "&read=" + pl.read : ""}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });

        });
    },
    view({ rootGetters, dispatch }, id) {
        return new Promise(function(resolve) {
            $api
                .get(`admin/ticket/${id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });
        });
    },
    create({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post("admin/ticket", pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    update({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .put(`admin/ticket/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }

                });
        });
    },
    delete({ rootGetters, dispatch }, pl) {
        return new Promise(async function(resolve) {
            $api
                .delete(`admin/ticket/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },

    templateGetList({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .get(`admin/ticket/templates?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });

        });
    },
    templateView({ rootGetters, dispatch }, id) {
        return new Promise(function(resolve) {
            $api
                .get(`admin/ticket/template/${id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                });
        });
    },
    templateCreate({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post("admin/ticket/template", pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    templateUpdate({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .put(`admin/ticket/template/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }

                });
        });
    },
    templateDelete({ rootGetters, dispatch }, pl) {
        return new Promise(async function(resolve) {
            $api
                .delete(`admin/ticket/template/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    commentCreate({ rootGetters, dispatch }, pl) {
        return new Promise(function(resolve) {
            $api
                .post(`admin/ticket/comment/${pl.ticket_id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }

                });
        });
    },
    commentDelete({ rootGetters, dispatch }, pl) {
        return new Promise(async function(resolve) {
            $api
                .delete(`admin/ticket/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function(res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function(err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => {});
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
};

export default {
    namespaced: true,
    actions
};