import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import Maska from 'maska'
import { i18n } from "./i18n.js"
import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import store from "./state/store";

import "../src/design/app.scss";
import "../src/design/css/custom.css";
import "../src/design/css/responsive.css";

import VueNumberFormat from 'vue-number-format'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import '@vueup/vue-quill/dist/vue-quill.bubble.css';
// import '@vueup/vue-quill/dist/vue-quill.core.css';
// import Quill from 'quill';

// import Pusher from 'pusher-js'

// window.MyPusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
//     cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
//     useTLS: true
// })
 document.getElementById('favicon').href = require(`@/assets/images/favicons/${process.env.VUE_APP_BRAND_NAME}.ico`);

const app = createApp(App)


app.config.devtools = false;
app.config.debug = false;
app.config.silent = true;

const globalOptions = {
    language: 'ko',
    theme: 'snow',
    modules: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            [{ color: [] }],
            [{ background: [] }],
            ['blockquote', 'code-block'],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
            [{ font: [] }],
            [{ align: [] }],
            ['clean']
        ]
    },

}
// set default globalOptions prop
QuillEditor.props.globalOptions.default = () => globalOptions

app.component('QuillEditor', QuillEditor)

app.use(store)
    .use(router)
    .use(require('vue-chartist'))
    .use(BootstrapVue3)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .use(Maska)
    .use(VueNumberFormat)
    .mount('#app')

// import $cookies from 'vue-cookies';

// if (!window.opener) {
//     window.addEventListener('load', function () {
//         var dateString = $cookies.get('unload_time') ? $cookies.get('unload_time') : null

//         if (dateString) {
//             const parts = dateString.match(/(\d+)/g);
//             const year = parseInt(parts[0]);
//             const month = parseInt(parts[1]) - 1; // Months are zero-based (0 = January, 1 = February, etc.)
//             const day = parseInt(parts[2]);
//             const hour = parseInt(parts[3]);
//             const minute = parseInt(parts[4]);
//             const second = parseInt(parts[5]);
//             const millisecond = parseInt(parts[6]);

//             const unload_time = new Date(Date.UTC(year, month, day, hour, minute, second, millisecond));
//             const current_time = new Date()

//             if (unload_time) {
//                 const timeDifference = Math.abs(current_time.getTime() - unload_time.getTime());
//                 const seconds = Math.floor((timeDifference % 60000) / 1000);
//                 if (seconds > 5) {
//                     store.commit('auth/destroyAuth')
//                 }
//             }
//         }
//     })

//     window.addEventListener('beforeunload', function () {
//         $cookies.set("unload_time", new Date());
//     })
// }

