import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/agents?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.username ? "&username=" + pl.username : ""}${pl.search ? "&search=" + pl.search : ""}${pl.referrer_id ? "&referrer_id=" + pl.referrer_id : ""}${pl.upline_id ? "&upline_id=" + pl.upline_id : ""}${pl.level ? "&level=" + pl.level : ""}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.sort ? "&sort=" + pl.sort : ""}${pl.order ? "&order=" + pl.order : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getPaymentWorksheet({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/agent/payment-worksheet?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.username ? "&username=" + pl.username : ""}${pl.search ? "&search=" + pl.search : ""}${pl.referrer_id ? "&referrer_id=" + pl.referrer_id : ""}${pl.upline_id ? "&upline_id=" + pl.upline_id : ""}${pl.level ? "&level=" + pl.level : ""}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.sort ? "&sort=" + pl.sort : ""}${pl.order ? "&order=" + pl.order : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getTransactionSummary({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/agent/transaction/list-merge-total?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.username ? "&username=" + pl.username : ""}${pl.search ? "&search=" + pl.search : ""}${pl.referrer_id ? "&referrer_id=" + pl.referrer_id : ""}${pl.upline_id ? "&upline_id=" + pl.upline_id : ""}${pl.level ? "&level=" + pl.level : ""}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.sort ? "&sort=" + pl.sort : ""}${pl.order ? "&order=" + pl.order : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getListTotal({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/agent/list-total?${pl.page_no ? "page=" + pl.page_no : ""}${pl.username ? "&username=" + pl.username : ""}${pl.search ? "&search=" + pl.search : ""}${pl.referrer_id ? "&referrer_id=" + pl.referrer_id : ""}${pl.upline_id ? "&upline_id=" + pl.upline_id : ""}${pl.level ? "&level=" + pl.level : ""}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}${pl.sort ? "&sort=" + pl.sort : ""}${pl.order ? "&order=" + pl.order : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getFilterList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/agent/filter?${pl.referrer ? "referrer=" + pl.referrer : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    view({ rootGetters, dispatch }, id) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/agent/${id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    viewPassword({ rootGetters, dispatch }, id) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/agent/password/${id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    create({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post("admin/agent", pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 201) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    updateAssets({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post(`admin/agent/${pl.asset}/${pl.action}/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    resolve({
                        success: false,
                        data: err.response.data
                    });
                });
        });
    },
    update({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .put(`admin/agent/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    else {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    logout({ rootGetters, dispatch }, pl) {
        return new Promise(async function (resolve) {
            $api
                .get(`admin/agent/logout/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if ([404, 422].includes(err.response.status)) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    delete({ rootGetters, dispatch }, pl) {
        return new Promise(async function (resolve) {
            $api
                .delete(`admin/agent/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    batchUpdate({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post(`admin/agent/batch/update/${pl.column}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
};

export default {
    namespaced: true,
    actions
};