import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/${pl.user || 'user'}/transactions?${pl.page_no ? "page=" + pl.page_no : ""}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}${pl.type ? "&type=" + pl.type : ""}${pl.role ? "&role=" + pl.role : ""}${pl.search ? "&search=" + pl.search : ""}${pl.role ? "&role=" + pl.role : ""}${pl.type ? "&type=" + pl.type : ""}${pl.username ? "&username=" + pl.username : ""}${pl.status ? "&status=" + pl.status : ""}${pl.user_id ? "&user_id=" + pl.user_id : ""}${pl.referrer_username ? "&referrer_username=" + pl.referrer_username : ""}${pl.user == 'player' ? "&online=" + pl.online : ''}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    getListTotal({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/${pl.user || 'user'}/transaction/list-total?${pl.type ? "type=" + pl.type : ""}${pl.search ? "&search=" + pl.search : ""}${pl.username ? "&username=" + pl.username : ""}${pl.status ? "&status=" + pl.status : ""}${pl.user_id ? "&user_id=" + pl.user_id : ""}${pl.referrer_username ? "&referrer_username=" + pl.referrer_username : ""}${pl.user == 'player' ? "&online=" + pl.online : ''}${pl.from && pl.to ? "&from=" + pl.from + "&to=" + pl.to : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });

        });
    },
    view({ rootGetters, dispatch }, id) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/player/${id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    create({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post("admin/player", pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 201) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
    update({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .put(`admin/${pl.user || 'user'}/transaction/${pl.id}`, pl.form_data, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    else {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }

                });
        });
    },
    delete({ rootGetters, dispatch }, pl) {
        return new Promise(async function (resolve) {
            $api
                .delete(`admin/player/${pl.id}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            data: res.data
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        resolve({
                            success: false,
                            data: err.response.data
                        });
                    }
                });
        });
    },
};

export default {
    namespaced: true,
    actions
};