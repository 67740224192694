import $api from "../api";

export const actions = {
    monthlyReport({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/report/monthly?${pl.date ? "date=" + pl.date : ""}${pl.username ? "&username=" + pl.username : ""}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 404) {
                        resolve(false)
                    }
                });

        });
    },
    dashboardSummary({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`admin/dashboard/graph-one`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 404) {
                        resolve(false)
                    }
                });

        });
    },
};

export default {
    namespaced: true,
    actions
};